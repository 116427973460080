import React, { useEffect, useState } from "react";
import useLetterGolf from "../hooks/useLetterGolf";
import LetterLines from "./LetterLines";
import Keypad from "./Keypad";
import Popup from "./Popup";


export default function LetterGolf({ solution, category }) {
    const { turn, handleKeyup, incorrectLetters, correctLetters, par, letterIndex, applyHintPenalty } = useLetterGolf(solution);
    const [hintUsed, setHintUsed] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State for showing the popup

    useEffect(() => {
        if (solution) {
            window.addEventListener("keyup", handleKeyup);
        }

        return () => {
            window.removeEventListener("keyup", handleKeyup);
        };
    }, [handleKeyup, solution]);

    const isGameWon = () => {
        return correctLetters.length === solution.length;
    };

    const rulesMessage = `
    Guess the word letter by letter from left to right.\n\n
    Correct guesses will show up on the lines. Incorrect guesses will be temporarily removed from the keyboard.\n\n
    Keys will reset after each correct guess.\n\n
    Try to guess the word in as few attempts as possible!
  `;

    const getScoreMessage = () => {
        const scoreDifference = turn - par;
        if (scoreDifference < 0) {
            return `You figured out today's word with a score of ${-scoreDifference} under par!`;
        } else if (scoreDifference > 0) {
            return `You figured out today's word with a score of ${scoreDifference} over par!`;
        } else {
            return `You figured out today's word with a score of par!`;
        }
    };

    const handleHintClick = () => {
        // Set a hint, e.g., revealing the first letter of the solution
        setHintUsed(true);
        applyHintPenalty();
    };

    const handleKeyPress = (letter) => {
        // Call the onKeyPress function (which should be handleKeyup) with the tapped letter
        handleKeyup({ key: letter });
    };

    const handleShare = async () => {
        const currentDate = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    
        let shareMessage = "⛳️ Letter Golf ⛳️\n\n" + currentDate + "\n";
        const scoreDifference = turn - par;
    
        if (scoreDifference > 0) {
            shareMessage += `I scored ${scoreDifference} over par on today's word.`;
        } else if (scoreDifference === 0) {
            shareMessage += "I saved par on today's word.";
        } else {
            shareMessage += `I scored ${Math.abs(scoreDifference)} under par on today's word.`;
        }
    
        if (hintUsed) {
            shareMessage += "\n(Hint used!)";
        }
    
        shareMessage += "\n\nhttps://www.letter-golf.com.";
    
        // Check if sharing is supported
        if (navigator.canShare && navigator.canShare({ text: shareMessage })) {
            try {
                await navigator.share({
                    text: shareMessage,
                });
                console.log("Results shared successfully!");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            // Fallback to SMS sharing
            const smsLink = `sms:?body=${encodeURIComponent(shareMessage)}`;
            window.open(smsLink);
        }
    };

    return (
        <div>
            <h2>Category - {category} (Par {par})</h2>
            <h2>Current score - {turn}</h2>
            <h3><LetterLines solution={solution} correctLetters={correctLetters} letterIndex={letterIndex} /></h3>
            <Keypad incorrectLetters={incorrectLetters} onKeyPress={handleKeyPress}/>
            
            {isGameWon() ? (
                <>
                <h3>{getScoreMessage()}</h3>
                <button className="button" onClick={handleShare}>Share</button>
                </>
            ) : (
                <>
                    {!hintUsed && <button className="button" onClick={handleHintClick}>Hint (+3 to score)</button>}
                </>
            )}
            {showPopup && <Popup message={rulesMessage} onClose={() => setShowPopup(false)} />}
            <h2><button className="button" onClick={() => setShowPopup(true)}> How to play </button></h2>
        </div>
    );
}
