import React, { useEffect, useState } from "react";
import LetterGolf from "./components/LetterGolf";
import wordsData from "./json/words.json"; // Adjust the path based on your project structure

function App() {
  // Function to format the current date
  const formatDate = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString(undefined, options);
  };

  const [solution, setSolution] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    // Calculate the current day of the year (1-365)
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 0);
    const diff = currentDate - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    
    // Select the word based on the current day of the year
    const index = dayOfYear % wordsData.length; // Use modulo to prevent out-of-bounds
    const selectedWord = wordsData[index];
    
    setSolution(selectedWord.word); // Set the solution as the word
    setCategory(selectedWord.category); // Set the category
  }, []);

  return (
    <div className="App">
      <h1>⛳️ Letter Golf ⛳️</h1>
      <h2>{formatDate()}</h2>
      {solution && <LetterGolf solution={solution} category={category} />}
    </div>
  );
}

export default App;
