// Popup.js
import React from "react";

const Popup = ({ message, onClose }) => {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>X</button>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default Popup;
