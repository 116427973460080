import React from "react";

const LetterLines = ({ solution, correctLetters, letterIndex }) => {
  return (
    <div className="letter-lines">
      {solution.split("").map((letter, index) => (
        <div key={index} className="letter-box">
          <span className="line">
            {index < letterIndex ? letter : ""}
          </span>
        </div>
      ))}
    </div>
  );
};

export default LetterLines;