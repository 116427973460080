import { useState, useEffect } from "react";
 
const useLetterGolf = (solution) => {

    const [par, setPar] = useState(0);
    const [turn, setTurn] = useState(0);
    const [currentGuess, setCurrentGuess] = useState("");
    const [incorrectLetters, setIncorrectLetters] = useState([]);
    const [letterIndex, setLetterIndex] = useState(0);
    const [correctLetters, setCorrectLetters] = useState([]);

    useEffect(() => {
        if (solution) {
            const length = solution.length;
            const parValue = (length * (length + 1)) / 2; // Sum of numbers from 1 to solution length
            setPar(parValue);
        }
    }, [solution]);

    const applyHintPenalty = () => {
        setTurn(turn + 3);
        setCorrectLetters((prevLetters) => {
            const newLetters = [...prevLetters]; // Copy the previous letters
            newLetters[letterIndex] = solution[correctLetters.length]; // Set only the current index
            setIncorrectLetters(""); // Clear incorrect letters
                setLetterIndex((prevIndex) => prevIndex + 1);
            return newLetters;
        });    
    };

    const handleKeyup = ({ key }) => {
        if (/^[A-Za-z]$/.test(key) && !incorrectLetters.includes(key)) {
            setCurrentGuess(""); // Clear the current guess after each keyup
    
            if (key.toLowerCase() === solution[letterIndex].toLowerCase()) {
                // If guess is correct, create a new array for correctLetters
                setCorrectLetters((prevLetters) => {
                    const newLetters = [...prevLetters]; // Copy the previous letters
                    newLetters[letterIndex] = key; // Set only the current index
                    return newLetters;
                });
                setIncorrectLetters(""); // Clear incorrect letters
                setLetterIndex((prevIndex) => prevIndex + 1);
            } else {
                // If guess is incorrect, add the letter to incorrectLetters
                setIncorrectLetters((prevLetters) => [...prevLetters, key]);
            }
    
            setTurn((turn) => turn + 1);
        }
    };


    return { turn, currentGuess, handleKeyup, incorrectLetters, correctLetters, par, letterIndex, applyHintPenalty };
};

export default useLetterGolf;
