import React from "react";

export default function Keypad({ incorrectLetters, onKeyPress }) {
    const firstRowLetters = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"];
    const secondRowLetters = ["a", "s", "d", "f", "g", "h", "j", "k", "l"];
    const thirdRowLetters = ["z", "x", "c", "v", "b", "n", "m"];

    const renderKeys = (letters) => {
        return letters.map((l) => {
            const color = incorrectLetters.includes(l) ? '#333' : '#eee'; // Dark gray for incorrect, light gray otherwise

            return (
                <div 
                    key={l} 
                    style={{ background: color }} 
                    className="key" 
                    onClick={() => onKeyPress(l)} // Add onClick handler
                >
                    {l}
                </div>
            );
        });
    };

    return (
        <div className="keypad">
            <div className="keypad-row">
                {renderKeys(firstRowLetters)}
            </div>
            <div className="keypad-row">
                {renderKeys(secondRowLetters)}
            </div>
            <div className="keypad-row">
                {renderKeys(thirdRowLetters)}
            </div>
        </div>
    );
}
